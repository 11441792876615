.custom-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.custom-table thead {
  background-color: #2093c3;
  color: #fff;
}

.custom-table th,
.custom-table td {
  padding: 21px 9px;
  border: 1px solid #ddd;
  white-space: nowrap;
}

.custom-table th {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}


.button-custom {
  background-color: #007bff;
  color: #fff !important;
  border: none;
  padding: 5px 10px 5px 10px !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.button-custom:hover {
  background-color: #0056b3 !important;
}
