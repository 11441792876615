/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';

@font-face {
  font-family: 'DroidKufi';
  src: url('assets/fonts/DroidKufiRegular.ttf');
}
html,
body {
    height: 100%;
}
.mat-table,.mat-form-field,.mat-button,.mat-paginator,.mat-tab-label,.mat-tab-group{
  font-family: 'Poppins', sans-serif !important;
}
.mat-table:lang(ar),.mat-form-field:lang(ar),.mat-button:lang(ar),.mat-paginator:lang(ar),.mat-tab-label:lang(ar),
.mat-tab-group:lang(ar) {
  font-family: 'DroidKufi' !important;
}
.mat-tab-label{
  font-size: 20px !important;
}
body {
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
}
html:lang(ar) {
  font-family: 'DroidKufi' !important;
}

body:lang(ar) {
  font-family: 'DroidKufi' !important;
}

.custom-file-input {
    display: none;
}
.text_error{
  color: red;
}
.upload-img {
    width: 300px;
    height: 300px;
    cursor: pointer;
}
.mat-elevation-z8 {
  overflow-x: auto;
  box-shadow: none;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.img_upload {
    text-align: center;
}

.upload-img1 {
    width: 150px;
    height: 150px;
    cursor: pointer;
}

.img_size {
    width: 300px;
    height: 150px;
    object-fit: contain;
}

select.form-control {
    color: #495057 !important;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 99%;
    pointer-events: auto;
    background-color: #ffffff;
    // background-clip: padding-box;
    border: 1px solid #e8ebf1;
    border-radius: 0.3rem;
    outline: 0;
    height: 500px;
    overflow-x: auto;
}
.switch {
    position: relative;
    border: none;
    height: 1rem;
    width: 2.2rem;
    border-radius: 2.5rem;
    background: transparent;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F21C471F;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
  }

  .slider:before {
    position: absolute;
    content: "";
    width: 11px;
    height: 11px;
    top: 2px;
    left: 3px;
    background: #F21C47;
    border-radius: 1.125rem;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #00C8531F;
  }

  input:focus + .slider {
    box-shadow: 0 0 0;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background: #00C853;
    left: -6px;
    position: absolute;
    right: 0;
  }
.mat-button{
  padding: 0 16px 0 0 !important;
}
select{
  color: #495057 !important;
}
.sidebar-folded .page-wrapper:lang(ar){
  margin-right: 70px !important;
}
.sidebar-folded .page-wrapper .navbar:lang(ar){
  right: 70px !important;
}
tr.mat-header-row {
  height: 56px;
  background: #27AAE1;
  box-shadow: 10px 10px 30px #27aae18d;
  color:  #ffffff !important;
}
.mat-sort-header-content{
  color:  #ffffff !important;
  text-transform: capitalize;
  font-weight: 500 !important;
}

.mat-header-cell {
  border-top: 0;
  border-bottom-width: 1px;
  padding: 10px 10px 18px 10px !important;
  text-align: left !important;
  color: #FBFBFB;
  font-size: 14px !important;
  font-weight: 500;
  background-color: #00C853 !important;
}
.mat-header-cell:lang(ar){
  text-align: right !important;
  font-weight: 500 !important;
}
.mat-cell {
  padding: 15px 10px 15px 10px !important;
  text-align: left!important;
  font-size: 14px!important;
}
.mat-cell:lang(ar){
  text-align: right !important;
}

.table td img {
  width: 36px;
  height: 36px;
  border-radius: 0px;
}

.tabel_img {
  width: 36px !important;
  height: 24px !important;
  border-radius: 0px !important;
}
.table thead th{
  color: #ffffff !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  text-align: center !important;
}
.mat-row:nth-child(even) {
  background-color: #f1f1f1;
}
.date_col {
  top: 0.7rem;
}
.date_col .mat-form-field-infix{
  width: auto !important;
}
.city_col {
  position: relative;
  top: 1.2rem;
}
.export_btn {
  box-shadow: 10px 10px 10px #00000029;
  background: transparent;
  border: 2px solid #27AAE1;
  color: #27AAE1;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  position: relative;
  top: 15px;
  margin-right: 10px;
}
.export_btn:lang(ar){
  font-size: 12px;
}
.des_p{
  padding: 5px 10px;
  margin-bottom: 0 !important;
} 
.text-right:lang(ar){
  text-align: left !important;
}
.mat-form-field-infix{
  width: 100% !important;
}
.mat-menu-panel{
  max-width: 400px !important;
}

.table-container {
  height: 100vh;
  overflow: auto;
}

@media (min-width: 2000px) {
  .table-container {
      height: 70vh !important;
      /* Set height to 70% of the viewport height */
  }
}

.fixed{
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: white !important;
}

.mat-sort-header-content {
  color: white !important;
  text-transform: capitalize;
  font-weight: 500 !important;
}

.mat-mdc-header-cell {
  background-color: #27AAE1  !important;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: none !important;
}

.custom-select {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  color: #333;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  appearance: none !important;
  background-image: url('data:image/svg+xml;base64,...') !important; /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.default-select{
  padding:8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: 100% !important
}

table th {
  vertical-align: middle;
  line-height: 1;
  white-space: nowrap;
  color: white;
}

