
// Booststrap functions and variables
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

// Template variables
@import './variables';

// Bootstrap main SCSS
@import 'bootstrap/scss/bootstrap.scss';

// Template mixins
@import './mixins/animation';
@import './mixins/buttons';
@import './mixins/cards';
@import './mixins/tooltips';
@import './mixins/width';

// Core styles
@import './background';
@import './reset';
@import './functions'; 
@import './footer';
@import './utilities';
@import './typography';
@import './spinner';

@import './vertical-wrapper';
@import './navbar';
@import './sidebar';
@import './layouts';

// components
@import "./components/tooltip";
@import "./components/table_custom";
@import "./components/accordions";
@import "./components/bootstrap-alert";
@import "./components/breadcrumbs";
@import "./components/buttons";
@import "./components/cards";
@import "./components/checkbox-radio";
@import "./components/dashboard";
@import "./components/datepicker";
@import "./components/dropdown";
@import "./components/forms";
@import "./components/icons";
@import "./components/input-group";
@import "./components/list-group";
@import "./components/modal";
@import "./components/nav";
@import "./components/tables";
@import "./components/auth";

// Plugin styles
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import "../fonts/feather/style.css";
@import 'animate.css/animate.min.css';
@import "@ng-select/ng-select/themes/default.theme.css";
// @import "metismenujs/scss/metismenujs";


// Plugin overrides
@import "./components/plugin-overrides/ng-select";
@import "./components/plugin-overrides/perfect-scrollbar";
// @import "./components/plugin-overrides/sweet-alert";
@import "./components/plugin-overrides/apex-charts";

