// .rtl {
//   .input-group > .input-group-append > .btn {
//     @include border-left-radius($border-radius);
//     @include border-right-radius(0);
//   }
//   .input-group {
//     > .form-control,
//     > .custom-select {
//       &:not(:last-child) {
//          @include border-left-radius(0); 
//          @include border-right-radius($border-radius); 
//       }
//       &:not(:first-child) { 
//         @include border-right-radius(0); 
//         @include border-left-radius($border-radius); 
//       }
//     }
//   }
// }


.input-style-n {
    height: 44.4px;
    border-radius: 10px;
    border: 1px solid #d7d5d5;
    padding: 0 20px;
    font-size: 16px;
}

.card-counter {
    width: 100%;
    box-shadow: 2px 2px 10px #d7d5d5;
    margin: 5px;
    padding: 20px 10px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid;

    &.green {
        border-color: #38e05e;
        background-color: #208a38;
        .header {
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .counter {
            color: #F1F1F1;
            font-size: 30px;
        }
    }

    &.blued {
        border-color: #3b8bba;
        background-color: #3b8bba;
        .header {
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .counter {
            color: #F1F1F1;
            font-size: 30px;
        }
    }

    &.purple {
        border-color: #8a3bba;
        background-color: #8a3bba;
        .header {
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .counter {
            color: #F1F1F1;
            font-size: 30px;
        }
    }

    &.darkgreen {
        border-color: #be188f;
        background-color: #ab1558;

        .header {
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .counter {
            color: #F1F1F1;
            font-size: 30px;
        }
    }

    &.bluesolid {
        border-color: #483bba;
        background-color: #433bba;

        .header {
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .counter {
            color: #F1F1F1;
            font-size: 30px;
        }
    }

    &.gray {
        border-color: #ea6161;
        background-color: #c52e2e;

        .header {
            color: #F1F1F1;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .counter {
            color: #F1F1F1;
            font-size: 30px;
        }
    }
}