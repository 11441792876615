/* Buttons */

.btn {
    font-size: $btn-font-size;
    line-height: 1;
    padding: .5rem 1rem;
    i {
        font-size: 1rem;
    }
    &.btn-rounded {
        @include border-radius(50px);
    }
    &.btn-xs {
        padding: $btn-padding-y-xs $btn-padding-x-xs;
        font-size: $btn-font-size-xs;
    }
    &.btn-sm {
        font-size: $btn-font-size-sm;
        padding: .45rem 1rem;
    }
    &.btn-lg {
        font-size: $btn-font-size-lg;
    }
    &.btn-link {
        color: (primary);
    }
    /* Buttons with only icons */
    &.btn-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
            height: 15px;
        }
        i {
            font-size: 15px;
        }
        &.btn-sm {
            width: 30px;
            height: 30px;
            svg {
                height: 13px;
            }
            i {
                font-size: 13px;
            }
        }
        &.btn-lg {
            width: 48px;
            height: 48px;
            svg {
                height: 20px;
            }
            i {
                font-size: 20px;
            }
        }
        .input-group-prepend &,
        .input-group-append & {
            width: 48px;
            height: 35px;
        }
    }
    /* Buttons with icon and text */
    &.btn-icon-text {
        display: inline-flex;
        align-items: center;
        .btn-icon-prepend {
            margin-right: .5rem;
        }
        .btn-icon-append {
            margin-left: .5rem;
        }
        .btn-icon-prepend,
        .btn-icon-append {
            width: 15px;
            height: 15px;
        }
        &.btn-sm {
            .btn-icon-prepend,
            .btn-icon-append {
                width: 12px;
                height: 12px;
            }
        }
        &.btn-lg {
            .btn-icon-prepend,
            .btn-icon-append {
                width: 20px;
                height: 20px;
            }
        }
    }
    &.btn-social-icon {
        width: 50px;
        height: 50px;
        padding: 0;
    }
}

.btn-group {
    .btn {
        +.btn {
            border-left: 0;
        }
    }
}

.rtl {
    .btn-group {
        .btn {
            +.btn {
                border-left: 1px solid;
                border-right: 0;
            }
        }
        >.btn:not(:first-child):not(:last-child):not(.dorpdown-toggle) {
            border-radius: 0;
        }
        // Reset rounded corners
        >.btn:not(:last-child):not(.dropdown-toggle),
        >.btn-group:not(:last-child)>.btn {
            border-radius: $btn-border-radius;
            // @include border-left-radius(0);
        }
        >.btn:not(:first-child),
        >.btn-group:not(:first-child)>.btn {
            border-radius: $btn-border-radius;
            // //@include border-right-radius(0);
        }
    }
}

.button-custom {
    margin: 10px;
    box-shadow: 10px 10px 10px #00000029;
    border: 2px solid #27AAE1;
    border-radius: 12px 0px 0px 12px;
    opacity: 1;
    padding: 10px 25px;
    color: #27AAE1;
    white-space: nowrap;
}

// .btn-toolbar {
//     .btn-group {
//         +.btn-group {
//             @extend .ml-2;
//         }
//     }
// }


/*social buttons*/

// @each $color,
// $value in $social-colors {
//     .btn-#{$color} {
//         @include social-button(social-color($color));
//     }
//     .btn-outline-#{$color} {
//         @include social-outline-button(social-color($color));
//     }
// }


/* inverse buttons */

// @each $color,
// $value in $s {
//     .btn-inverse-#{$color} {
//         @include button-inverse-variant($value);
//     }
// }

.btn-outline-info {
    &:hover {
        color: #fff;
    }
}

.rtl {
    .btn {
        /* Buttons with icon and text */
        &.btn-icon-text {
            .btn-icon-prepend {
                margin-right: 0;
                margin-left: .5rem;
            }
            .btn-icon-append {
                margin-left: 0;
                margin-right: .5rem;
            }
        }
    }
    .btn-group {
        .btn {
            +.btn {
                border-left: 1px solid;
                // border-right: 1px solid;
            }
        }
        >.btn:not(:first-child):not(:last-child):not(.dorpdown-toggle) {
            border-radius: 0;
        }
        // Reset rounded corners
        >.btn:not(:last-child):not(.dropdown-toggle),
        >.btn-group:not(:last-child)>.btn {
            border-radius: $btn-border-radius;
            // @include border-left-radius(0);
        }
        >.btn:not(:first-child),
        >.btn-group:not(:first-child)>.btn {
            border-radius: $btn-border-radius;
            //@include border-right-radius(0);
        }
    }
}

.btn-own {
    margin: 10px;
    box-shadow: 10px 10px 10px #00000029;
    border: 2px solid #27AAE1;
    border-radius: 12px 0px 0px 12px;
    opacity: 1;
    padding: 10px 25px;
    color: #27AAE1;
}

.btn-own-cancel{
       margin: 10px;
    box-shadow: 10px 10px 10px #00000029;
    border: 2px solid #e66356;
    border-radius: 0px 12px 12px 0px;
    opacity: 1;
    padding: 10px 25px;
    color: #e66356;
}
