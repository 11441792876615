.tooltip_element {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .text {
    visibility: hidden;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    font-size: 15px;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
    padding: 8px;
    border-radius: 5px;
    position: absolute;
    z-index: 10;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    }
  }

  &:hover .text {
    visibility: visible;
    opacity: 1;
  }
}
